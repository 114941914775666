import { useCallback, useState } from "react";
import "./App.css";

function App() {
  const [imageToDisplay, setImageToDisplay] = useState<string | null>();

  const onClick = useCallback(() => {
    const imageNumber = Math.floor(Math.random() * (30 - 1) + 1);

    setImageToDisplay(`cards/${imageNumber}.jpg`);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {imageToDisplay ? (
          <p className="card-text card-quote">
            <img alt="quote img-fluid" src={`/${imageToDisplay}`}></img>
          </p>
        ) : (
          <p></p>
        )}
        <button onClick={onClick} className="btn btn-lg btn-primary">
          mācība + rīcība
        </button>
      </header>
      <p className="bg-dark text-info text-start">
        <div className="description">
          <p>Apraksts</p>
          <ul>
            <li>"Mācība" - izrunājiet</li>
            <li>"Rīcība" - dariet </li>
            <li>Ieteiktās dziesmas var noklausīties YouTube</li>
          </ul>
        </div>
      </p>
    </div>
  );
}

export default App;
